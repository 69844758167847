<template>
  <div class="animated-logo">
    <svg
      class="animated-logo__vector"
      viewBox="0 0 255 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="logo"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="logo-fill"
          transform="translate(-69.000000, -243.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
          stroke="#565656"
        >
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M86.548,245.39 L96.46,271.766 C97.006,273.152 97.384,273.404 99.148,273.74 L99.148,273.74 L99.148,275 L86.38,275 L86.38,273.74 C88.48,273.53 89.362,273.152 88.606,271.094 L88.606,271.094 L86.758,266.012 L77.518,266.012 L76.09,270.59 C75.292,273.068 76.258,273.32 80.08,273.74 L80.08,273.74 L80.08,275 L70.336,275 L70.336,273.74 C72.646,273.362 73.276,273.152 73.78,271.43 L73.78,271.43 L82.012,245.39 L86.548,245.39 Z M81.844,252.404 L81.76,252.404 L78.064,264.206 L86.086,264.206 L81.844,252.404 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M112.752,275 L112.752,273.866 C110.602759,273.663241 110.293727,273.53879 110.27497,272.434153 L110.274,244.34 L109.602,244.34 L100.782,245.348 L100.782,246.482 C103.008,246.692 103.26,246.86 103.26,248.414 L103.26,272.312 C103.26,273.53 103.008,273.656 100.782,273.866 L100.782,275 L112.752,275 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M125.516,253.58 C129.8,253.58 133.79,255.47 133.79,260.552 C133.79,261.35 133.706,262.19 133.496,263.072 L133.496,263.072 L121.442,263.072 L121.442,263.66 C121.442,269.414 124.76,271.682 128.12,271.682 C130.262,271.682 132.026,270.884 133.118,270.17 L133.118,270.17 L133.706,271.556 C133.034,272.312 130.514,275.42 125.096,275.42 C118.04,275.42 114.428,270.38 114.428,264.71 C114.428,257.57 120.266,253.58 125.516,253.58 Z M125.306,255.344 C123.206,255.344 121.904,257.318 121.526,261.182 L121.526,261.182 L127.448,261.182 C127.7,260.342 127.784,259.208 127.784,258.704 C127.784,256.058 126.482,255.344 125.306,255.344 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M142.774,275 L142.774,273.866 C139.918,273.656 139.75,273.194 140.548,272.018 L143.698,267.44 L146.218,271.64 C147.226,273.32 146.554,273.656 145.126,273.866 L145.126,275 L156.97,275 L156.97,273.866 C155.5,273.656 154.786,273.194 154.198,272.228 L148.486,262.82 L152.812,257.276 C153.904,255.89 154.576,255.512 156.55,255.092 L156.55,254 L148.486,254 L148.486,255.092 C151.006,255.302 151.258,256.226 150.124,257.738 L147.52,261.182 L145.42,257.696 C144.244,255.806 144.916,255.344 146.386,255.092 L146.386,254 L134.626,254 L134.626,255.092 C136.264,255.344 136.726,255.89 137.314,256.856 L142.648,265.718 L138.112,272.06 C137.188,273.362 136.474,273.656 134.206,273.866 L134.206,275 L142.774,275 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M184.64,275.42 C187.916,275.42 190.856,274.454 192.788,273.446 L194.174,275.21 L195.35,275.21 L195.35,264.164 C195.35,262.946 195.602,262.778 197.66,262.568 L197.66,261.308 L185.522,261.308 L185.522,262.568 C187.748,262.778 188,262.946 188,264.164 L188,273.026 C187.076,273.404 186.068,273.614 184.976,273.614 C180.524,273.614 177.374,269.792 177.374,260.3 C177.374,250.892 180.86,246.986 185.312,246.986 C190.352,246.986 192.494,250.598 193.334,254.042 L194.594,254.042 L194.174,247.7 C192.494,246.65 189.512,245.18 185.312,245.18 C176.366,245.18 169.646,251.48 169.646,260.3 C169.646,269.12 175.694,275.42 184.64,275.42 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M210.214,253.58 C214.498,253.58 218.488,255.47 218.488,260.552 C218.488,261.35 218.404,262.19 218.194,263.072 L218.194,263.072 L206.14,263.072 L206.14,263.66 C206.14,269.414 209.458,271.682 212.818,271.682 C214.96,271.682 216.724,270.884 217.816,270.17 L217.816,270.17 L218.404,271.556 C217.732,272.312 215.212,275.42 209.794,275.42 C202.738,275.42 199.126,270.38 199.126,264.71 C199.126,257.57 204.964,253.58 210.214,253.58 Z M210.004,255.344 C207.904,255.344 206.602,257.318 206.224,261.182 L206.224,261.182 L212.146,261.182 C212.398,260.342 212.482,259.208 212.482,258.704 C212.482,256.058 211.18,255.344 210.004,255.344 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M230.622,253.58 C235.788,253.58 238.728,256.436 238.644,260.006 L238.644,260.006 L238.644,271.388 C238.644,271.976 238.98,272.354 239.61,272.354 C240.114,272.354 240.66,272.102 241.206,271.766 L241.206,271.766 L241.374,272.942 C239.694,274.286 237.51,275.42 235.116,275.42 C232.722,275.42 231.63,274.118 231.63,272.564 L231.63,272.564 L231.63,272.06 L231.546,272.06 C230.118,274.16 228.018,275.42 225.498,275.42 C222.306,275.42 220.5,273.236 220.5,270.464 C220.5,267.86 222.558,264.332 231.63,263.786 L231.63,263.786 L231.63,259.88 C231.63,257.696 230.58,255.722 228.018,255.722 C226.296,255.722 225.036,256.898 225.036,258.116 C225.036,259.376 226.464,259.67 226.464,260.888 C226.464,261.854 225.372,262.652 223.818,262.652 C222.432,262.652 221.13,262.064 221.13,260.468 C221.13,258.074 224.49,253.58 230.622,253.58 Z M231.63,265.256 C229.11,265.634 227.64,266.726 227.64,268.868 C227.64,270.38 228.396,271.22 229.614,271.22 C230.37,271.22 231.042,270.884 231.63,270.422 L231.63,270.422 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M255.314,275 L255.314,273.866 C252.338176,273.662176 252.013258,273.537484 251.996719,272.416667 L251.996,259.838 C252.92,259.082 253.76,258.746 254.39,258.746 C255.566,258.746 256.07,259.796 257.582,259.796 C259.346,259.796 260.354,258.284 260.354,256.772 C260.354,254.63 258.842,253.58 257.414,253.58 C254.81,253.58 253.34,255.722 251.996,258.41 L251.786,258.41 L251.996,253.58 L251.324,253.58 L242.504,255.218 L242.504,256.31 C244.73,256.562 244.982,256.73 244.982,258.284 L244.982,272.312 C244.982,273.53 244.73,273.656 242.504,273.866 L242.504,275 L255.314,275 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M267.28,251.984 C269.548,251.984 271.312,250.85 271.312,248.624 C271.312,246.356 269.548,245.18 267.28,245.18 C265.054,245.18 263.248,246.356 263.248,248.624 C263.248,250.85 265.054,251.984 267.28,251.984 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M273.454,275 L273.454,273.866 C271.304759,273.663241 270.995727,273.53879 270.97697,272.434153 L270.976,253.58 L270.304,253.58 L261.484,255.218 L261.484,256.31 C263.71,256.562 263.962,256.73 263.962,258.284 L263.962,272.312 C263.962,273.53 263.71,273.656 261.484,273.866 L261.484,275 L273.454,275 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M287.016,275 L287.016,273.866 C284.866759,273.663241 284.557727,273.53879 284.53897,272.434153 L284.538,259.376 C285.378,258.62 286.596,257.612 288.444,257.612 C290.04,257.612 290.796,258.662 290.796,260.384 L290.796,272.312 C290.796,273.53 290.544,273.656 288.822,273.866 L288.822,275 L300.288,275 L300.288,273.866 C298.138759,273.663241 297.829727,273.53879 297.81097,272.434153 L297.81,259.88 C297.81,255.26 295.164,253.58 292.056,253.58 C288.99,253.58 286.47,255.47 284.538,257.99 L284.328,257.99 L284.538,253.58 L283.866,253.58 L275.046,255.218 L275.046,256.31 C277.272,256.562 277.524,256.73 277.524,258.284 L277.524,272.312 C277.524,273.53 277.272,273.656 275.046,273.866 L275.046,275 L287.016,275 Z"
          />
          <path
            id="motion-path"
            stroke-linecap="round"
            d="M311.036,253.58 C312.9176,253.58 314.46992,253.945867 315.727108,254.54101 L315.992,254.672 L322.166,253.958 L322.166,256.604 L318.134,256.352 C319.268,257.654 319.856,259.208 319.856,260.762 C319.856,265.004 316.328,268.154 311.036,268.154 C309.986,268.154 308.978,268.07 308.012,267.818 C307.634,268.238 307.256,268.784 307.256,269.33 C307.256,269.931364 307.715223,270.417921 309.108548,270.53397 L309.314,270.548 L315.488,270.8 C320.486,271.01 322.586,273.824 322.586,276.764 C322.586,281.888 316.748,283.82 309.524,283.82 C304.148,283.82 300.788,282.35 300.788,279.914 C300.788,278.15 302.72,276.89 304.61,275.882 C303.56,275.378 302.342,274.37 302.342,272.27 C302.342,270.17 304.82,268.364 306.794,267.44 C303.98,266.348 302.006,264.122 302.006,260.888 C302.006,256.646 305.744,253.58 311.036,253.58 Z M305.996,276.26 C305.408,277.268 305.282,277.94 305.282,278.57 C305.282,281.132 307.76,282.056 310.742,282.056 C313.22,282.056 316.412,281.384 316.412,279.032 C316.412,277.562 315.236,276.932 313.388,276.806 L313.388,276.806 Z M310.826,255.344 C309.188,255.344 308.432,256.94 308.432,260.384 C308.432,265.172 309.944,266.39 311.246,266.39 C313.178,266.39 313.43,264.206 313.43,261.392 C313.43,256.94 312.254,255.344 310.826,255.344 Z"
          />
        </g>
      </g>
    </svg>
    <div
      id="animated-logo__highlight"
      class="animated-logo__highlight"
    />
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  name: 'AnimatedLogo',
  mounted () {
    const timeline = anime.timeline()

    timeline.add({
      duration: 750,
    })

    timeline.add({
      targets: '#motion-path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1000,
      delay: function (el, i) { return i * 100 },
    })

    timeline.add({
      targets: '#logo-fill',
      duration: 500,
      fill: '#565656',
      easing: 'linear',
    })

    timeline.add({
      targets: '#animated-logo__highlight',
      duration: 1000,
      width: '100%',
      left: 0,
      easing: 'easeOutBack',
    })
  },
}
</script>

<style lang="scss" scoped>

.animated-logo {
  position: relative;

  &__vector {
    width: calc(100% - var(--rem--xxxs) - var(--rem--xxxs));
    height: auto;
    position: relative;
    z-index: 200;
    padding: 0 var(--rem--xxxs);
  }

  &__highlight {
    position: absolute;
    top: 50%;
    width: 0;
    background-color: #D8EEF7;
    height: 50%;
    z-index: 100;
    left: 50%;
  }
}

</style>
