var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'section-content',
    { 'section-content--is-left': !_vm.hasImageOnLeft }
  ]},[_c('div',{class:[
      'section-content__container',
      'section-content__container-text',
      `section-content__container-text--width--${_vm.textWidth}`,
    ]},[_vm._t("text")],2),_c('div',{class:[
      'section-content__container',
      'section-content__container-image',
      { 'section-content__container-image--is-left': _vm.hasImageOnLeft }
    ]},[_vm._t("image")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }